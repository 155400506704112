import Vue from 'vue';
/**
 * @typedef {import('./state').cryptoState} cryptoState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get crypto wallets
     * @param {cryptoState} state
     * @returns {cryptoState['createPageOwnerDetails']}
     */
    getCryptoWalletList(state) {
      return state.cryptoWalletList;
    },
    /**
     * get crypto transactions
     * @param {cryptoState} state
     * @returns {cryptoState['transactions']}
     */
    getCryptoTransactions(state) {
      return state.transactions;
    },
    /**
     * get crypto transactions
     * @param {cryptoState} state
     * @returns {cryptoState['selectedQuote']}
     */
    getQuoteDetails(state) {
      return state.selectedQuote;
    },
    /**
     * get buy crypto form data
     * @param {cryptoState} state
     * @returns {cryptoState['buyCryptoFormData']}
     */
    getBuyCryptoFormData(state) {
      return state.buyCryptoFormData;
    },
    /**
     * get crypto form data
     * @param {cryptoState} state
     * @returns {cryptoState['sellCryptoFormData']}
     */
    getSellCryptoFormData(state) {
      return state.sellCryptoFormData;
    },
    /**
     * get send crypto form data
     * @param {cryptoState} state
     * @returns {cryptoState['sendCryptoFormData']}
     */
    getSendCryptoDetails(state) {
      return state.sendCryptoFormData;
    },
    /**
     * get estimated gas fess
     * @param {cryptoState} state
     * @returns {cryptoState['estimatedGasFees']}
     */
    getEstimatedGasFees(state) {
      return state.estimatedGasFees;
    },
  },
  mutations: {
    /**
     * set crypto wallet list
     * @param {cryptoState} state
     * @param {cryptoState['cryptoWalletList']} payload
     */
    setCryptoWalletList(state, payload) {
      state.cryptoWalletList = payload;
    },
    /**
     * set crypto wallet list
     * @param {cryptoState} state
     * @param {cryptoState['transactions']} payload
     */
    setCryptoTransactions(state, payload) {
      state.transactions = payload;
    },
    /**
     * set quote details
     * @param {cryptoState} state
     * @param {cryptoState['buyCryptoFormData']} payload
     */
    setBuyCryptoFormData(state, payload) {
      state.buyCryptoFormData = payload;
    },
    /**
     * set quote details
     * @param {cryptoState} state
     * @param {cryptoState['sellCryptoFormData']} payload
     */
    setSellCryptoFormData(state, payload) {
      state.sellCryptoFormData = payload;
    },
    /**
     * set quote details
     * @param {cryptoState} state
     * @param {cryptoState['selectedQuote']} payload
     */
    setQuoteDetails(state, payload) {
      state.selectedQuote = payload;
    },
    /**
     * set send crypto data
     * @param {cryptoState} state
     * @param {cryptoState['sendCryptoFormData']} payload
     */
    setSendCryptoDetails(state, payload) {
      state.sendCryptoFormData = payload;
    },
    /**
     * set estimated gas fees
     * @param {cryptoState} state
     * @param {cryptoState['estimatedGasFees']} payload
     */
    setEstimatedGasFees(state, payload) {
      state.estimatedGasFees = payload;
    },
  },
  actions: {
    /**
     * fetch all crypto wallets
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    listAllCryptoWallets({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/crypto')
          .then(({ data }) => {
            commit('setCryptoWalletList', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * fetch all crypto wallets
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    getWalletById({ commit }, id) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/crypto/${id}`)
          .then(({ data }) => {
            commit('account/setSelectedAccount', data, {root: true});
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
    * create account
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {any} payload
     * @returns {Promise<cryptoState['wallet']>}
     */
    createCryptoWallet({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/crypto', payload)
          .then(({ data }) => {
            commit('setSelectedCryptoWallet', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch crypto transactions
     */
    fetchCryptoTransactions({commit, state}, {accountId, limit = 25, offset = 0, minAmount, maxAmount, startDate, endDate, query, txnType}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${accountId}/transaction?limit=${limit}&offset=${offset}`;
        if (startDate) {
          url += `&startTime=${startDate}`;
        }
        if (endDate) {
          url += `&endTime=${endDate}`;
        }
        if (minAmount && minAmount !== '0.00' && minAmount != '0') {
          url += `&minAmount=${minAmount}`;
        }
        if (maxAmount && maxAmount !== '0.00' && maxAmount != '0') {
          url += `&maxAmount=${maxAmount}`;
        }
        if (query) {
          url += `&query=${query}`;
        }
        if (txnType) {
          url += `&txnType=${txnType}`;
        }
        http
          .get(url)
          .then(({ data }) => {
            let d = {};
            if(offset === 0) {
              d = data;
            } else {
              const transactions = {...state.transactions};
              d = {
                data: [...transactions.data,...data.data],
                total: data.total
              }
            }
            commit('setCryptoTransactions', d);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * fetch transaction by id
     */
    getCryptoTransactionById({commit}, {accountId, transactionId, format}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${accountId}/transaction/${transactionId}`;
        if (format) {
          url += `?export=${format}`;
        }
        http(url, {
          method: 'GET',
          responseType: format === 'pdf' ? 'blob' : 'json'
        })
          .then(({ data }) => {
            if (format !== 'pdf') {
              commit('transaction/setSelectedTransaction', data, {root: true});
            }
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * get buy quote
     */
    getBuyQuote({commit}, {walletId, cryptoCurrency, amount, quantity}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${walletId}/buy/quote?cryptoCurrency=${cryptoCurrency}`;

        if(amount) {
          url+=`&amount=${amount}`;
        } else if(quantity) {
          url+=`&quantity=${quantity}`;
        }
        
        http
          .get(url)
          .then(({ data }) => {
            commit('setQuoteDetails', data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * buy crypto
     */
    // eslint-disable-next-line no-unused-vars
    buyCrypto({commit}, {walletId, quoteId, accountId, title, description}) {
      return new Promise((resolve, reject) => {
        const url = `v1/crypto/${walletId}/buy`;
        
        http
          .post(url, {
            quoteId,
            accountId,
            title,
            description
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * get sell quote
     */
    getSellQuote({commit}, {walletId, cryptoCurrency, amount, quantity}) {
      return new Promise((resolve, reject) => {
        let url = `v1/crypto/${walletId}/sell/quote?cryptoCurrency=${cryptoCurrency}`;

        if(amount) {
          url+=`&amount=${amount}`;
        } else if(quantity) {
          url+=`&quantity=${quantity}`;
        }
        
        http
          .get(url)
          .then(({ data }) => {
            commit('setQuoteDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },

    /**
     * sell crypto
     */
    // eslint-disable-next-line no-unused-vars
    sellCrypto({commit}, {walletId, quoteId, accountId, title, description}) {
      return new Promise((resolve, reject) => {
        const url = `v1/crypto/${walletId}/sell`;
        
        http
          .post(url, {
            quoteId,
            accountId,
            title,
            description
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * send crypto
     */
    sendCrypto({commit}, { walletId, destinationCryptoAddress, cryptoCurrency, quantity, description }) {
      return new Promise((resolve, reject) => {
        http
          .post(`v1/crypto/${walletId}/send`, {
            destinationCryptoAddress,
            cryptoCurrency,
            quantity,
            description
          })
          .then(({ data }) => {
            commit('setSendCryptoDetails', data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get gas fees
     */
    getGasFees({commit}, { cryptoCurrency }) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/crypto/send/estimate?cryptoCurrency=${cryptoCurrency}`)
          .then(({ data }) => {
            commit('setEstimatedGasFees', data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
