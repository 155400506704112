import Kyb from './index.vue';

const routes = [
  {
    path: '/kyb',
    name: 'Kyb',
    component: Kyb,
    children: [
      {
        path: 'businessDetails',
        name: 'BusinessDetails',
        component: () =>
          import(
            /* webpackChunkName: "kyb-business-details" */ './views/BusinessDetails.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'businessAddress',
        name: 'BusinessAddress',
        component: () =>
          import(
            /* webpackChunkName: "kyb-business-address" */ './views/BusinessAddress.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'businessStatus',
        name: 'BusinessStatus',
        component: () =>
          import(
            /* webpackChunkName: "kyb-business-status" */ './views/BusinessStatus.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'ownership',
        name: 'YourOwnership',
        component: () => import('./views/BusinessOwners/YourOwnership.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'ownersList',
        name: 'OwnersList',
        component: () => import('./views/BusinessOwners/OwnersList.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'ownerDetails',
        name: 'OwnerDetails',
        component: () => import('./views/BusinessOwners/OwnerDetails.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'ownerAddress',
        name: 'OwnerAddress',
        component: () => import('./views/BusinessOwners/OwnerAddress.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'ownerOwnership',
        name: 'OwnerOwnership',
        component: () => import('./views/BusinessOwners/OwnerOwnership.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
    ]
  }
];

export default routes;