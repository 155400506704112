import Crypto from './index.vue';

const routes = [
  {
    path: '/crypto',
    name: 'Crypto',
    component: Crypto,
    children: [
      {
        path: 'add',
        name: 'AddCryptoToWalletTo',
        component: () =>
          import(
            /* webpackChunkName: "crypto-wallet-addto" */ '@m/account/views/AddAccount.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          page: 'crypto'
        }
      },
      {
        path: 'setup',
        name: 'SetupCryptoWallet',
        component: () =>
          import(
            /* webpackChunkName: "crypto-wallet-setup" */ './views/SetupCryptoWallet.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true
        }
      },
      {
        path: 'success',
        name: 'CryptoWalletStatus',
        component: () =>
          import(
            /* webpackChunkName: "crypto-wallet-status" */ './views/CryptoWalletStatus.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: false,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
